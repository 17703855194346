.n-wrapper
{
    height: 10vh;
   display: flex;
   justify-content: space-between;
    
}


.n-left
{
   flex: 1;
   display: flex;
    align-items: center;
    gap: 1rem;
}

.n-name
{
    font-weight: bold;
    font-size: 1.2rem;
    font-family: 'Inter', sans-serif;


}

.n-right
{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    
}
.n-list
{
    margin-right: 2rem;
}

.n-list>ul
{
   display: flex;
   gap: 2rem;
   font-size: 1.07rem;
   list-style: none;
}

.n-list>ul>li:hover
{
   color: rgb(255, 32, 32);
   cursor: pointer;
}
    
.button
{
    
    width: 6rem;
    padding: .3rem;
    border-radius: 6px;
    cursor: pointer;
    background: rgb(199,31,31);
background: linear-gradient(90deg, rgba(199,31,31,1) 0%, rgba(255,24,24,1) 100%);
border: none;
color: white;
font-weight: bold;
font-size: 0.9rem;
}

.button:hover
{
    scale: 1.1;
    background: white;
    color: black;
    border: 1px solid black;
    
}


.btn {
    position: relative;
    padding: 1rem 3rem;
    font-family: Lato, sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
    color: black;
    text-decoration: none;
    background-color: white;
    border: transparent;
    outline: transparent;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    animation: glow 8s linear infinite;
  
    &-gradient {
      color: white;
      background: linear-gradient(90deg, #00dbde, #fc00ff, #00dbde);
      background-size: 300%;
      border-radius: 2em;
  
      &::before {
        position: absolute;
        content: "";
        top: -5px;
        left: -5px;
        bottom: -5px;
        right: -5px;
        z-index: -1;
        background: inherit;
        background-size: inherit;
        border-radius: 4em;
        opacity: 0;
        transition: 0.5s;
      }
  
      &:hover::before {
        opacity: 1;
        filter: blur(20px);
        animation: glow 8s linear infinite;
      }
    }
  }
  
  @keyframes glow {
    to {
      background-position: 300%;
    }
  }


  .btn-hover {
    width: 120px;
    font-size: 13.5px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    height: 35px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
  
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn-hover:focus {
    outline: none;
  }
  .btn-hover.color-3 {
    background-image: linear-gradient(to right, #ff5be9, #f97342, #f7186a, #fbb03b);
    box-shadow: 0 4px 15px 0 rgba(255, 149, 229, 0.75);
  }
  .char
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #ef3535 0%,
    #f034d7 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

  

@media screen and (max-width: 450px) {

    .n-list{
        display:none;
    }
    
}