.co-wrapper
{
    height: 80vh;
    position: relative;
    display: flex;
}

.co-left
{
    display: flex;
    flex-direction: column;
    position: absolute;
  margin-left: 1.5rem;
  margin-top: -1rem;
}

.back12
{
    
    position: absolute;
    left: 18rem;
    width: 30%;
    top: 15.5rem;
    
}

.back12> img{
    
    scale: 2.4;
    
    z-index: -1;
    opacity: 50%;
  

}


.co-left
{
    flex: 1;
    flex-direction: column;
    display: flex;
    
}

.co-left>:nth-child(2)
{
    font-size: 2.3rem;
}

.co-left>:nth-child(3)
{
    font-size: 2.1rem;
    font-weight: bold;
    color: rgb(255, 18, 18);
    margin-top: .5rem;
}

.co-left>:nth-child(4)
{
    margin-top: 1.5rem;
    color: rgb(81, 72, 72);
}

.co-right{
    
    z-index: 2;
    margin-top: 10rem;
}


.box
{
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 22rem;
    width: 15rem;
    background: rgb(248,239,255);
    background: linear-gradient(90deg, rgba(248,239,255,1) 0%, rgba(255,204,254,1) 100%);
    margin-left: 47rem;
    margin-top: -12rem;
    gap: .5rem;
    padding: 1.5rem;
    border: 3.5px solid rgb(212, 49, 103);
    border-radius: 10px ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.but2
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-left: 4rem;
    max-width: 100%;

}

input
{
   height: 1.8rem;
   background-color: rgb(255, 254, 243);
}


@media screen and (max-width:480px)
 {
    .co-wrapper
    {
       gap: 5rem;
        height: 130vh;
        
    }

    .co-right
    {
        position: absolute;
        left: -47rem;
        max-width: 100%;
        top: 38rem;
      

    }

    .co-left
    {
        position: absolute;
    display: flex;
    flex-direction: column;
        margin-top: 15rem;
    }

    .back12
    {
        top: 39rem;
    }
}