.Floatingdiv
{
    justify-content: space-around;
    display: flex;
    background: rgb(136,201,255);
background: linear-gradient(90deg, rgba(136,201,255,1) 5%, rgba(196,237,255,1) 51%, rgba(255,147,221,1) 95%);
align-items: center;
    
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    height: 4rem;
    padding: 0px 26px 0px 0px;
    
    
}
.Floatingdiv>img
{
    scale: 0.4;
    padding-left: -15rem;

}



