.services
{
    height: 100vh;
    
}

.back2
{
    position: absolute;
    z-index: -1;
    opacity: 35%;
   top: 4rem;
    scale: 1.35;
    left: 10%;
}

.s-left
{
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    
    position: relative;
    width: 75%;
    
}

.s-left>:nth-child(2)
{
    font-size: 2.3rem;
}

.s-left>:nth-child(3)
{
    font-size: 2.1rem;
    font-weight: bold;
    color: rgb(255, 18, 18);
    margin-top: .5rem;
}

.s-left>:nth-child(4)
{
    margin-top: 1rem;
    color: rgb(81, 72, 72);
}

.s-left>:nth-child(5)
{
    margin-top: 2rem;
    width: 15rem;
}

.cards
{
    flex: 1;
    display: flex;
    position: relative;
    gap: 1rem;
}

.cards>*
{
  position: absolute;
}
.but1
{
    width: 7rem;
}

@media screen and (max-width:480px)
{
    .s-left
    {
        width: 100%;
    }
    .services
    {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        height: 170vh;
    }

    .cards
    {
       left:3rem !important;
       scale: 0.9;
       display: flex;
       flex-direction: column;
       gap: 3.5rem;
    }
    .cards > *
    {
        
        position: static;
    }
    .back2{
        top: .5rem;
        
    }
    .back2>img{
        width: 50rem;
        height: 30vh;
        position: absolute;
    }
}