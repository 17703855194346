.t-container
{
    position: relative;
    height: 100vh;
    margin-top: 10rem;
}

.t-cube
{
    position: absolute;
    top: 5rem;
    left: 34rem;
    height: 45%;
    width: 80%;

}

.back7
{
    
    position: absolute;
    z-index: -1;
    top: -15%;
    
}

.back7 >img{
    
    scale: 0.7;
    position: absolute;
   left: 45rem;
    opacity: 30%;

}



.t-wrapper
{
    height: 80vh;
    
}

.t-left
{
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    flex: 1;
    position: relative;
    width: 75%;
    
}

.t-left>:nth-child(1)
{
    font-size: 2.3rem;
}

.t-left>:nth-child(2)
{
    font-size: 2.1rem;
    font-weight: bold;
    color: rgb(255, 18, 18);
    margin-top: .5rem;
}

.t-left>:nth-child(3)
{
    margin-top: 2rem;
    color: rgb(81, 72, 72);
}

.t-left>:nth-child(4)
{
    margin-top: 1rem;
    color: rgb(81, 72, 72);
}

.t-left>:nth-child(5)
{
    margin-top: 1rem;
    color: rgb(81, 72, 72);
}

@media screen and (max-width:480px) 
{
    .t-wrapper{
        height: 130vh;
    }
    .t-left{
        width: 100%;
        
    }
    .t-cube{
        position: absolute;
        left: -31rem;
        width: 80rem;
        top: 40rem;
        
}
}