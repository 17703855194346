.card
{
    display: flex;
    height: 17rem;
    width:12rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgb(243,249,255);
background: linear-gradient(90deg, rgba(243,249,255,1) 5%, rgba(255,253,231,1) 51%, rgba(255,220,244,1) 95%);
gap: 1rem;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border:3px solid rgb(252, 93, 93);
}

.card>:nth-child(1)
{
    scale: 0.6;
}