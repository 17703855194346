.experience
{
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 2rem;
    position: relative;
}
.back1
{
    position: absolute;
    z-index: -1;
    opacity: 25%;
}
.exp
{
 display: flex;
 align-items: center;
 flex-direction: column;

    
}

.txt1
{
    height: 4rem;
    width: 4rem;
    background: rgb(255,240,248);
background: linear-gradient(90deg, rgba(255,240,248,1) 0%, rgba(255,214,242,1) 100%);
border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: bold;
}

.exp>:nth-child(2)
{
    margin-top: 1.5rem;
}

.exp>:nth-child(3)
{
    color: red;
}

.txt1::before
{
  border-radius: 100%;
  content: '';
  top:-9px;
  bottom: -9px;
  right: -9px;
  left: -9px;
  background: rgb(255,90,195);
background: linear-gradient(90deg, rgb(255, 103, 199) 0%, rgb(253, 29, 48) 100%);
position: absolute;
z-index: -1;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

@media screen and (max-width:480px) {
    
    .experience
    {
        gap: 1rem;
        height: 30vh;
        padding-top: 10rem;
        
       
    }
   

}